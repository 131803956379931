import React from 'react';
import './styles.scss';
import icons from '../../assets/icons/icons';

const Loader = ({background = ''}) => {


    return (
        <div className='loadingFull' style={background ? {background: background}: {}}>
            Загрузка...
            {/* <object type="image/svg+xml" data={icons.loadingGeo}></object> */}
        </div>
    );
};

export default Loader;
