import mapColors from "../../mapColors"

const getGeoObjectColor = (object) => {
    if (object.color)
        return object.color

    switch (object.object_type) {
        case 'Скважина':
            return mapColors.green
        case 'Канализация':
            return mapColors.yellow
        case 'Газопровод':
            return mapColors.orange
        case "Водопровод":
            return mapColors.cyan
        default:
            return mapColors.grayblue
    }
}

export default getGeoObjectColor