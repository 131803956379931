import { YMaps, Map, TypeSelector, ZoomControl } from '@pbe/react-yandex-maps'
import React, { useEffect, useMemo, useState } from 'react'
import './MapComponent.scss'
import MapControlPanel from './MapControlPanel'
import Button from '../Buttons/Button'
import { ReactComponent as LandplotEditIcon } from '../../assets/icons/Pencil.svg'
import { ReactComponent as DistrictEditIcon } from '../../assets/icons/penEdit.svg'
import MapSwitchGeoObject from './MapSwitchGeoObject'
import getArrayDepth from '../UI/functions/getArrayDepth'
import mapColors from '../UI/mapColors'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import checkCoordinates from '../UI/functions/checkCoordinates'
import getLandplotStrokeColor from '../UI/functions/map/getLandplotStrokeColor'
import getGeoObjectColor from '../UI/functions/map/getGeoObjectColor'
import getLandplotFillColor from '../UI/functions/map/getLandplotFillColor'

const MapComponent = (props) => {
    const { classes = '',
        district = [],
        districtRedact = false,
        landplots = [],
        activeLandplot = null,
        landplotRedact = false,
        geoObjects = [],
        geoObjectRedact = false,
        activeGeoObject = null,
        drawType = 'polygon',
        drawColor = null,
        save = () => { },
        isRedact = false,
        onClose = () => { } } = props

    const history = useHistory()
    const location = useLocation()
    const [mapRef, setMapRef] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [newCoords, setNewCoords] = useState([])
    const [newLine, setNewLine] = useState(false)
    const isNewDrawing = useMemo(() => {
        if (isRedact) {
            if (landplotRedact) {
                return !checkCoordinates(activeLandplot?.coordinates)
            } else if (districtRedact) {
                return !checkCoordinates(district)
            } else if (geoObjectRedact) {
                if (drawType === 'polyline') {
                    setNewCoords(geoObjects?.coordinates || geoObjects?.[0]?.coordinates)
                }
                return !(geoObjects?.coordinates?.length > 0 || geoObjects?.[0]?.coordinates?.length > 0)
            }
        }
        return false
    }, [activeLandplot, district, geoObjects])

    const MAP_LOCATION = [50.585989778936856, 36.58355299999997]
    const MAXPOINTS = 50

    useEffect(() => {
        // console.log('newCoords', newCoords)
    }, [newCoords])
    useEffect(() => {
        console.log('geoObjects', geoObjects)
    }, [geoObjects])

    useEffect(() => {
        setNewLine(isNewDrawing)
    }, [])

    useEffect(() => {
        if (mapRef)
            setMapCenter(mapRef)
    }, [mapRef])

    const setMapCenter = (ref) => {
        setTimeout(() => {  // ждем прогрузки объектов (хз)
            ref.setBounds(ref.geoObjects.getBounds())
        }, 100)
    }

    const startEdit = () => {
        setIsEdit(true)
    }

    const endEdit = () => {
        if (newCoords.length > 0) {
            const to_save = window.confirm('Сохранить новые координаты?')
            if (to_save) {
                saveNewCoordinates(newCoords)
            }
            setNewCoords([])
        }
        setIsEdit(false)
    }

    const saveNewCoordinates = (geomenry) => {
        save(geomenry)
        setNewCoords(null)
        setIsEdit(false)
        onClose()
    }

    const getFillColor = (landplot) => {
        if ((getArrayDepth(landplot.coordinates) === 2 && landplot.coordinates.length > MAXPOINTS)
            || (getArrayDepth(landplot.coordinates) === 3 && landplot.coordinates[0].length > MAXPOINTS)) {
            return `${mapColors.errorRed}80`
        }
        if (location.pathname === '/controlsMap') // Карта контроля строительства
            return getLandplotFillColor(landplot.stage)
        else {
            // Остальные карты

            if (landplot.status === 'В работе' && landplot.sub_status === "Социально значимый объект")
                return `${mapColors.purple}80`
            return getLandplotFillColor(landplot.status)
        }

    }

    const strokeColor = (landplot) => {
        if ((getArrayDepth(landplot.coordinates) === 2 && landplot.coordinates.length > MAXPOINTS)
            || (getArrayDepth(landplot.coordinates) === 3 && landplot.coordinates[0].length > MAXPOINTS)) {
            return mapColors.errorRed
        }
        // Карта контроля строительства
        if (location.pathname === '/controlsMap') {
            if (landplot.is_control_department) {
                return '#d92d20';
            }
            else {
                return getLandplotStrokeColor(landplot.stage)
            }
        } else {
            if (landplot.status === 'В работе' && landplot.sub_status === "Социально значимый объект")
                return mapColors.purple
            // Остальные карты
            return getLandplotStrokeColor(landplot.status)
        }
    }

    const setLine = (idx) => {
        return function (geomenry) {
            if (Array.isArray(newCoords)) {
                const newArr = [...newCoords]
                newArr[idx] = geomenry
                setNewCoords([...newArr])
            }
        }
    }

    const addLine = (geomenry) => {
        if (Array.isArray(newCoords)) {
            const newArr = [...newCoords]
            newArr[newArr.length] = geomenry
            setNewCoords([...newArr])
        }
    }

    const handleMapClick = (e) => {
        const coords = e.get('coords');
        if (isEdit) {
            if (isNewDrawing) {
                if (newCoords.length === 0 || newLine) {
                    if (newLine) {
                        setNewCoords(prevCoords => [...prevCoords, [[...coords]]])
                        setNewLine(false)
                    } else {
                        setNewCoords([[[...coords]]])
                    }
                }
            } else {

            }
        } else {
            return
        }
    };

    return <YMaps query={{ apikey: 'a2718abd-328d-4bd5-a0e1-f246345a30d4' }} >
        <Map defaultState={{ center: MAP_LOCATION, zoom: 11, checkZoomRange: true }} className={classes}
            modules={["geoObject.addon.editor", 'geoObject.addon.hint']}
            instanceRef={ref => setMapRef(ref)}
            onClick={handleMapClick}
        // mode={'debug'} 
        >

            <TypeSelector options={{ float: isRedact ? "left" : "right" }} />
            <ZoomControl options={{ float: "none", position: isRedact ? { top: 100, left: 20 } : { top: 100, right: 20 } }} />

            {isRedact && <>
                <MapControlPanel classes={'_instrumentsBlock'}>
                    {(landplotRedact || districtRedact || geoObjectRedact) && <Button Icon={LandplotEditIcon}
                        onClick={() => isEdit ? endEdit() : startEdit()}
                        className='btn-secondary _grayMapIconButton'
                        active={isEdit}
                    />}

                    {/* кнопка для добавления дополнительных полилайнов */}
                    {geoObjectRedact && drawType === 'polyline' && isEdit && <Button Icon={DistrictEditIcon}
                        onClick={() => setNewLine(true)}
                        className='btn-secondary _grayMapIconButton'
                        active={newLine}
                    />}
                </MapControlPanel>
                <MapControlPanel classes={'_saveBlock'}>
                    <Button
                        onClick={() => {
                            if (newCoords.length > 0) { // если есть несохраненные изменения на карте, спрашиваем
                                const to_close = window.confirm('Закрыть окно без сохранения изменений?')
                                if (to_close) {
                                    onClose()
                                }
                            } else {
                                onClose()
                            }
                        }}
                        title={'Отменить'}
                        className='btn-secondary _xs _block'
                    />
                    <Button
                        onClick={() => saveNewCoordinates(newCoords)}
                        title={'Сохранить'}
                        className='btn _green _xs _block'
                        disabled={!(newCoords.length > 0)}
                    />
                </MapControlPanel>
            </>}

            {/* рисунок */}
            {newLine && drawType === 'polyline' && <MapSwitchGeoObject
                geometry={[]}
                type={'polyline'}
                options={{
                    editorDrawingCursor: "crosshair",
                    strokeColor: drawColor ? drawColor : mapColors.orange,
                    strokeWidth: 2,
                    strokeStyle: "dot",
                    editingMode: true,
                }}
                isEdit={isEdit}
                isNewDrawing={true}
                onChange={(geomenry) => {
                    setNewLine(false)
                    addLine(geomenry)
                    // setIsNewLine([...geomenry])
                }} />}

            {isEdit && <>{drawType === 'polyline' ?
                newCoords.map((line, idx) => <MapSwitchGeoObject
                    key={idx}
                    geometry={line}
                    type={drawType}
                    options={{
                        editorDrawingCursor: "crosshair",
                        strokeColor: drawColor ? drawColor : mapColors.orange,
                        strokeWidth: 2,
                        strokeStyle: "dot",
                        editingMode: true,
                    }}
                    isEdit={true}
                    isNewDrawing={true}
                    onChange={setLine(idx)} />
                )

                : <MapSwitchGeoObject
                    geometry={newCoords}
                    type={drawType}
                    options={{
                        editorDrawingCursor: "crosshair",
                        fillColor: drawColor ? `${drawColor}80` : `${mapColors.orange}80`,
                        strokeColor: drawColor ? drawColor : mapColors.orange,
                        strokeWidth: 2,
                        strokeStyle: "dot",
                        editingMode: true,
                    }}
                    isEdit={isEdit}
                    isNewDrawing={true}
                    onChange={setNewCoords}
                />
            }</>}

            {district?.length > 0 &&
                <MapSwitchGeoObject
                    geometry={district}
                    type='polygon'
                    options={{
                        strokeColor: mapColors.grayblue,
                        fillColor: 'rgba(255, 255, 255, 0)',
                        strokeWidth: 3,
                        strokeStyle: "solid",
                        editingMode: true,
                    }}
                    isEdit={districtRedact && isEdit}
                    onChange={districtRedact && setNewCoords}
                />
            }

            {landplots && landplots.map(landplot => {
                return <MapSwitchGeoObject
                    key={landplot.id}
                    type={'polygon'}
                    geometry={getArrayDepth(landplot.coordinates) > 2 ? landplot.coordinates : [landplot.coordinates]}
                    options={{
                        editorDrawingCursor: "crosshair",
                        fillColor: getFillColor(landplot),
                        strokeColor: strokeColor(landplot),
                        opacity: landplot.cadastral === activeLandplot?.cadastral ? 1 : landplot.is_control_department ? 1 : 0.4,
                        strokeWidth: landplot.is_control_department ? 4 : 2,
                        strokeStyle: "solid",
                    }}
                    properties={(() => {
                        if (isRedact) {
                            return {}
                        } else {
                            return {
                                hintContent:
                                    '<div className="tooltipContainer">' +
                                    `<p>Собственник: ${landplot.contragent ? landplot.contragent : 'не указан'}</p>` +
                                    `<p>Кадастровый номер: ${landplot.cadastral}</p>` +
                                    `<p>Статус: ${landplot.status}</p>` +
                                    `<p>Доп.статус: ${landplot.sub_status}</p>` +
                                    '</div>',
                            }
                        }
                    })()}
                    isActive={landplot.cadastral === activeLandplot?.cadastral}
                    isEdit={(landplot.cadastral === activeLandplot?.cadastral) && landplotRedact && isEdit}
                    onClick={!isRedact ? () => { history.push(`/landplot/${landplot.id}`) } : false}
                    onChange={landplot.cadastral === activeLandplot?.cadastral && landplotRedact && setNewCoords}
                />
            })}

            {geoObjects?.length > 0 && geoObjects.map((object) => {
                const geoObjectDepth = getArrayDepth(object.coordinates)
                if (geoObjectDepth === 3) {
                    if (!(geoObjectRedact && isEdit)) {
                        return object.coordinates.map((line, idx) => {
                            return <MapSwitchGeoObject
                                key={idx}
                                geometry={line}
                                type={'polyline'}
                                onClick={!isEdit ? () => { history.push(`/geoobject/${object.id}`) } : () => { }}
                                options={{
                                    strokeColor: getGeoObjectColor(object),
                                    opacity: 0.7,
                                    strokeWidth: 4,
                                    strokeStyle: "solid",
                                }}
                                className={isNewDrawing && isEdit ? 'poligon' : ''}
                                properties={{
                                    hintContent:
                                        '<div className="tooltipContainer">' +
                                        `<div>${object.name ? object.name : 'название не указано'}</div>` +
                                        '</div>',
                                }}
                                isEdit={geoObjectRedact && isEdit}
                                onChange={geoObjectRedact && setLine(idx)}
                            />
                        })
                    }
                } else {
                    return (
                        <MapSwitchGeoObject
                            key={object.id}
                            geometry={object.coordinates}
                            type={'placemark'}
                            onClick={!isEdit ? () => { history.push(`/geoobject/${object.id}`) } : () => { }}
                            options={{ iconColor: getGeoObjectColor(object) }}
                            className={isNewDrawing && isEdit ? 'poligon' : ''}
                            properties={{
                                hintContent:
                                    '<div className="tooltipContainer">' +
                                    `<div>${object.name ? object.name : 'название не указано'}</div>` +
                                    '</div>',
                            }}
                            isEdit={geoObjectRedact && isEdit}
                            onChange={geoObjectRedact && setNewCoords}
                        />
                    )
                }
            })}

        </Map>
    </YMaps>
}

export default MapComponent