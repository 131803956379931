import mapColors from "../../mapColors"

const getLandplotFillColor = (string) => {
    switch (string) {
        // Карта контроля строительства
        case 'STAGE_1':
            return `${mapColors.green}80`
        case "STAGE_2":
            return `${mapColors.yellow}80`
        case "STAGE_3":
            return `${mapColors.orange}80`
        case "STAGE_4":
            return `${mapColors.cyan}80`
        case "STAGE_5":
            return `${mapColors.cyan}80`
        case "STAGE_6":
            return `${mapColors.grayblue}80`

        // Остальные карты
        case 'В работе':
            return `${mapColors.lightblue}80`
        case 'Первичная бронь':
            return `${mapColors.yellow}80`
        case 'В продаже':
            return `${mapColors.green}80`
        case 'Подтвержденная бронь':
            return `${mapColors.orange}80`
        case "Реализовано":
            return `${mapColors.grayblue}80`
        default:
            return `${mapColors.grayblue}80`
    }
}

export default getLandplotFillColor