import React from 'react';
import './styles.scss'
import icons from '../../assets/icons/icons';

const Checkbox = ({ id, label, checked, onChange, count, className, name = '' }) => {
    return (
        <div className='checkbox-container'>
            <div className={`checkFlex style_${checked && id} ${className}`}>
                <div className='checkbox'>
                    <input
                        type="checkbox"
                        id={name + id}
                        checked={checked}
                        onChange={() => onChange(id)}
                        className={`checkbox-input style_${id} ${className}`}
                    />
                    <object type="image/svg+xml" data={icons.Checkmark} className='checkboxMark'></object>
                </div>
                <label htmlFor={name + id} className={`checkbox-label ${checked ? 'checked' : ''} ${className ? 'checked' : ''}`}>
                    {label}
                </label>
                <p htmlFor={id} className={`checkboxCount ${checked ? 'checked' : ''} ${className ? 'checked' : ''}`}>
                    {count}
                </p>
            </div>
        </div>
    );
};

export default Checkbox;
