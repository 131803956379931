import getArrayDepth from "./getArrayDepth"

const checkCoordinates = (coordinates) => {
    if (Array.isArray(coordinates)) {
        if (coordinates.length === 0)
            return false
        const polygonGeometry = getArrayDepth(coordinates) > 2 ? coordinates : [coordinates]
        if (polygonGeometry[0]?.[0]?.length > 0)
            return true
    }
    return false
}

export default checkCoordinates