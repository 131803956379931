import React from 'react'
import './MapControlPanel.scss'

const MapControlPanel = ({classes = '', children}) => {
  return (
    <div className={`MapControlPanel ${classes}`}>
        {children}
    </div>
  )
}

export default MapControlPanel