import { configureStore } from '@reduxjs/toolkit'

import userDataReducer from './userDataSlice';
import microdistDataReduces from './microdistDataReduces';
import needsRerenderReducer from './needsRerenderSlice';
import filterDataReduces from './filterDataReduces';
import loadingReducer from './loadingReducer';
import updateSidebar from './updateSidebar';
import filterLandploatDataReduces from './filterLandploatDataReduces';
import filterClientsDataReduces from './filterClientsDataReduces';
import filterMicrodistrictDataReduces from './filterMicrodistrictDataReduces';
import filterReportDataReduces from './filterReportDataReduces';
import filterObjectsDataReduces from './filterObjectsDataReduces';

export const store = configureStore({
  reducer: {
    user_data: userDataReducer,
    mocrodist_data: microdistDataReduces,
    needsRerender: needsRerenderReducer,
    data_filter: filterDataReduces,
    data_landploatFilter: filterLandploatDataReduces,
    data_reportFilter: filterReportDataReduces,
    data_objectFilter: filterObjectsDataReduces,
    data_microdistFilter: filterMicrodistrictDataReduces,
    data_clientsFilter: filterClientsDataReduces,
    loading: loadingReducer,
    updateSidebar: updateSidebar,
  },
})