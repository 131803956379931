import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Sidebar from '../component/Sidebar/Sidebar';
import {jwtDecode} from 'jwt-decode';
import UserApiRequest from '../api/User/User';
import { setUserDataItem } from '../store/userDataSlice';
import { getCookie } from '../component/UI/functions/functions';
import apiConfig from '../api/apiConfig';
import Loader from '../component/Loader/Loader';
import generateSidebarData from './generateSidebarData';
import AllRouting from './geosRouting/AllRouting';


const Routes = () => {
  const dispatch = useDispatch()
  const addUserData = (name, value) => dispatch(setUserDataItem(name, value))

  const userData = useSelector(state => state.user_data.userData);
  const sbMedia = generateSidebarData(userData);

  const location = useLocation();
  const history = useHistory();

  const userApi = new UserApiRequest();

  let userToken = getCookie("token");
  let userTokenRefresh = getCookie("refresh");

  localStorage.setItem("token", userToken)
  localStorage.setItem("refresh", userTokenRefresh)


  const [isUpdate, setIsUpdate] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Изменено на локальный стейт
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    if (userToken != undefined || userToken != null) {

      const userId = jwtDecode(userToken).user_id;
      userApi.getById({ urlParams: userId }).then((resp) => {
        if (resp.success) {
          localStorage.setItem("user", JSON.stringify(resp.data))

          addUserData(['user', resp.data])
          addUserData(['user_id', userId])
          addUserData(['token', userToken])
          addUserData(['refresh', userTokenRefresh])
          setIsUpdate(true)
          setIsAuthenticated(true); // Устанавливаем isAuthenticated в true после успешной авторизации
          setIsLoading(false)
        }
      })
    } else {
      window.location.replace(`${apiConfig.urlCollective}login/?next=${apiConfig.urlGeo}`);
    }
  }, [userToken, isUpdate])

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };
    const intervalId = setInterval(checkOnlineStatus, 5000);
    // Очистка интервала при размонтировании компонента
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      if (isAuthenticated) {
        history.push('/');
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    // Подписка на события онлайн/оффлайн
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Отписка от событий при размонтировании компонента
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isAuthenticated, history]);

  useEffect(() => {
    if (!isOnline && isAuthenticated && !location.pathname.includes('/')) {
      history.push('/errorNetwork');
    }
  }, [isOnline, isAuthenticated, location.pathname, history]);

  return (
    <>
    {isLoading ? (
      <Loader />
    ) : (
      <div className='main'>
        <Sidebar sbData={sbMedia} pageType={'ControlPanel'} />
        <div className='page'>
          <Switch>
            {isAuthenticated && (
              <AllRouting isAuthenticated={isAuthenticated} />
            )}
            <Redirect to='/error' />
          </Switch>
        </div>
      </div>
    )}
  </>
  );
};

export default Routes;
