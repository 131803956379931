import React, { useEffect, useState } from 'react';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import './style.scss';
import FileAttached from '../UI/FileAttached/FileAttached';

const FormBlock = ({ onClick, title, textAddButton, dataPlatform, onChange, dataOptions, initData = [], searchSelect = null, classDict = {} }) => {
    const [data, setData] = useState(() => {
        let arr = []
        for (let i = 0; i < initData?.length; i++) {
            arr.push(dataOptions)
        }
        return arr
    });
    const [dataBlock, setDataBlock] = useState(initData);

    const addFormBlock = () => {
        const newData = [
            ...data,
            dataOptions
        ];
        setData(newData);
    };

    const addPlatformBlock = () => {
        const newData = [
            ...data,
            dataOptions
        ];
        setData(newData);
    };

    const addDataBlock = (item = {}) => {
        const newBlockData = item;

        setDataBlock([...dataBlock, newBlockData]);
    };

    const handleBlockRemove = (index) => {
        const newData = [...data];
        const newDataBlock = [...dataBlock];
        newData.splice(index, 1);
        newDataBlock.splice(index, 1);

        setData(newData);
        setDataBlock(newDataBlock);
        onChange(newDataBlock);
    };



    const handleFieldChange = (blockIndex, fieldName, value) => {

        const updatedBlocksData = [...dataBlock];
        const updatedBlock = { ...updatedBlocksData[blockIndex] };

        updatedBlock[fieldName] = value;

        updatedBlocksData[blockIndex] = updatedBlock;

        setDataBlock(updatedBlocksData);
        onChange(updatedBlocksData);

    };

    useEffect(() => {
        dataPlatform && addPlatformBlock()
    }, [])

    return (
        <div>
            <div>
                {data.map((block, blockIndex) => (
                    <div key={blockIndex} className={'backGray'}>
                        <div className='headerFounder'>
                            <h1>{`${title} ${blockIndex + 1}`}</h1>
                            {(dataPlatform && blockIndex === 0) ?
                                <></>
                                :
                                <img
                                    className='closeButton'
                                    src={icons.Close}
                                    alt='Remove'
                                    onClick={() => handleBlockRemove(blockIndex)}
                                />
                            }
                        </div>
                        <div className='inputContainerCol'>
                            {block.map((item, idx) => {
                                
                                if (item && item.key !== 'id') {// Add a check for item
                                    if (item.key === 'files') {
                                        return <div className='col-3' style={{ display: 'flex', flexDirection: 'column', gap: '12rem', marginTop: '12rem' }}>
                                            <FileAttached
                                                filesData={dataBlock[blockIndex] ? dataBlock[blockIndex][item.key] : ''}
                                                setFiles={(e) => { handleFieldChange(blockIndex, 'files', e) }}
                                                addFile={true}
                                                oldUpload={true}
                                                onDelete={(i) => {
                                                    handleFieldChange(blockIndex, 'files', dataBlock[blockIndex]?.[item.key]?.filter(file => file.id !== i.id))
                                                }}
                                            /></div>
                                    } else {
                                        return <FormInput
                                            key={item.key}
                                            keyData={item.key}
                                            subInput={item?.value?.label}
                                            required={item?.value?.required}
                                            options={searchSelect?.[item.key]?.options ? searchSelect[item.key].options : item?.value?.choices}
                                            type={item?.value?.type}
                                            style={`mt-16 ${classDict[item.key] !== undefined ? classDict[item.key] : 'col-3'}`}
                                            value={dataBlock?.length > 0 && dataBlock[blockIndex] ? dataBlock[blockIndex][item.key] : ''}
                                            onChange={(e) => {
                                                handleFieldChange(blockIndex, item.key, e);
                                            }}
                                            onScroll={searchSelect?.[item.key]?.onScroll !== undefined ? searchSelect[item.key].onScroll : null}
                                            onSearch={searchSelect?.[item.key]?.onSearch !== undefined ? searchSelect[item.key].onSearch : null}
                                            loadingScroll={searchSelect?.[item.key]?.loadingScroll !== undefined ? searchSelect[item.key].loadingScroll : null}
                                        />
                                    }
                                }
                                return <></>
                            })}
                        </div>

                    </div>
                ))}
            </div>
            <Buttons
                ico={icons.plusSquare}
                text={textAddButton}
                className={'whiteButton _full'}
                onClick={dataPlatform ? addPlatformBlock : () => {
                    addFormBlock();
                    addDataBlock();
                }}
            />

        </div>
    );
};

export default FormBlock;
