import React from 'react'
import App from './App'
import AppContainer from './AppContainer'
import { createRoot } from 'react-dom/client'

const domNode = document.getElementById('root')
const root = createRoot(domNode);

root.render(<AppContainer>
  <App />
</AppContainer>);
