export default {
    black: '#1d2939',
    gray: '#98a2b3',
    green: '#66C61C',
    teal: '#2ED3B7',
    red: '#F04438',
    errorRed: '#D92D20',
    orange: '#EF6820',
    yellow: '#EAAA08',
    blue: '#2970FF',
    lightblue: '#0BA5EC',
    darkblue: '#2970FF',
    grayblue: '#B3B8DB',
    cyan: '#22CCEE',
    purple: '#7A5AF8',
    fuchsia: '#D444F1',
    rose: '#F63D68',
}
