import React from 'react';

const Button = ({ title, Icon, onClick, className = 'btn', active = false, disabled = false }) => {


    return (
        <button onClick={onClick} className={`${className ? className : 'btn'}${active ? ' _active' : ''}`} disabled={disabled} >
            {title && <span>{title}</span>}
            {Icon && <Icon className={'btn__icon'} />}
        </button>
    )
}

export default Button