import React, { useEffect, useState } from 'react'
import './style.scss'
import icons from '../../assets/icons/icons'
import EmojiPicker from 'emoji-picker-react'
import FormSelector from '../FormSelector/FormSelector'
import InputMask from 'react-input-mask'
import ToggleButton from '../ToggleButton/ToggleButton'
import { getDateTime, getFormattedStandartDate } from '../UI/functions/functions'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Calendar } from 'primereact/calendar'

import { addLocale } from 'primereact/api'
import Buttons from '../Buttons/Buttons'


const FormInput = ({
    id,
    type,
    textArea,
    placeholder,
    style,
    value,
    onChange,
    onScroll,
    onSearch,
    subInput,
    required,
    error,
    description,
    disabled,
    ico,
    question,
    options,
    mask,
    keyData,
    checked,
    loading,
    tip,
    loadingScroll,
    icoButton,
    textButton,
    onChangeButton,
    styleButton,
    buttonInput,
    multiple
}) => {
    const [isLoading, setIsLoading] = useState(loading);
    const [valueSet, setValueSet] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [isErr, setIsErr] = useState(error);

    const [date, setDate] = useState('')

    useEffect(() => {
        setIsLoading(loading);

        // Если isLoading становится false, обновляем valueSet
        if (!isLoading) {
            setValueSet(value === undefined ? '' : value);
        }
    }, [loading, isLoading, value]);

    // useEffect(() => {
    //     setValueSet(value === undefined && !isLoading ? '' : value)

    //     if (type === 'date' && value) {
    //         const r_ru = /^([0-2][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/
    //         const r_en = /^(\d{4})\-(0[1-9]|1[0-2])\-([0-2][0-9]|3[01])$/
    //         if (r_ru.test(value)) {
    //             const date_arr = value.split('.')
    //             const new_date = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`
    //             const date_value = new Date(new_date)
    //             // setDate(date_value)

    //             // setDate(value)
    //         } else if (r_en.test(value)) {
    //             const date_value = new Date(value)
    //             // setDate(date_value)

    //             // setDate(getFormattedStandartDate(value))
    //             // onChange('11.11.1111')
    //         } else {
    //             const date_value = new Date(value)
    //             // setDate(date_value)

    //             // setDate(value)
    //         }
    //     }
    //     if (type === 'datetime' && value) {
    //         // setDate(new Date(value))
    //         // setDate(value)
    //     }
    //     // }, [value]);
    // }, []);

    useEffect(() => {
        if (valueSet) {
            if ((type === 'date' || type === 'datetime')) {
                const r_en = /^(\d{4})\-(0[1-9]|1[0-2])\-([0-2][0-9]|3[01])$/

                if (r_en.test(valueSet)) {
                    setDate(getFormattedStandartDate(valueSet))
                    // setDate(valueSet)
                } else {
                    setDate(valueSet)
                }

            }
        }
    }, [valueSet]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '') {
            setIsErr(true)
        } else {
            setIsErr(false)
        }
        setValueSet(newValue);
        onChange(newValue);
    };

    const handleChangeDate = (event) => {
        // const original_value = event.originalEvent.nativeEvent.target.value
        // if (original_value) {
        //     const clear_value = original_value.replace(/\D/g, "")
        //     if (clear_value.length >= 8) {
        //         const new_date_string = `${clear_value.slice(0, 2)}.${clear_value.slice(2, 4)}.${clear_value.slice(4, 8)}`
        //         const d = new Date(new_date_string)
        //         if (Object.prototype.toString.call(d) === "[object Date]") {
        //             // it is a date
        //             if (isNaN(d)) { // d.getTime() or d.valueOf() will also work
        //                 // date object is not valid
        //                 setIsErr(true)
        //             } else {
        //                 // date object is valid
        //                 onChange(new_date_string);
        //                 setIsErr(false)
        //             }
        //         } else {
        //             // not a date object
        //             setIsErr(true)
        //         }
        //     } else {
        //     }
        // } else {
        //     setIsErr(false)
        //     if (event.value)
        //         onChange(getFormattedStandartDate(event.value));
        // }

        // setDate(event.value)
        console.log('event', event)
        // onChange(event.originalEvent.nativeEvent.target.value);

    };

    const handleEmojiSelect = (emoji) => {
        setValueSet((prevValue) => prevValue + emoji.emoji);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(valueSet);
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 3000);
    };

    const handleEmailMask = (event) => {
        // Применяем маску к полю ввода электронной почты
        const email = event.target.value;
        const maskedEmail = email
            .replace(/[^A-Za-z0-9@._-]/g, '') // Удаляем недопустимые символы
            .replace(/^(.{0,2})@/, '$1@') // Ограничиваем длину локальной части до 64 символов
            .replace(/@([^.]+)$/, '@$1') // Ограничиваем доменную часть одной точкой
            .replace(/([._-])\1+/g, '$1'); // Удаляем повторяющиеся точки, подчеркивания и дефисы

        if (email === '') {
            setIsErr(true)
        } else {
            setIsErr(false)
        }
        setValueSet(maskedEmail);
        onChange(maskedEmail);
    };

    const handlePhoneChange = (e) => {
        const phoneNumber = e.target.value.replace(/\D/g, ''); // Filter out non-numeric characters

        setValueSet(phoneNumber.slice(1));
        onChange(phoneNumber.slice(1));
    };


    const handleLink = (event) => {
        const inputValue = event.target.value;

        // Проверяем, начинается ли введенный текст с "https://", "http://" или "www."
        if (inputValue.startsWith('https://') || inputValue.startsWith('http://') || inputValue.startsWith('www.')) {
            setValueSet(inputValue);
            onChange(inputValue);
        } else {
            // Если не начинается, добавляем "https://"
            const correctedValue = `https://${inputValue}`;
            setValueSet(correctedValue);
            onChange(correctedValue);
        }
    };

    const handleToggle = (formDataKey, isChecked) => {
        setValueSet(formDataKey, isChecked);
        onChange(formDataKey, isChecked);
    };

    const [skeletonHeight, setSkeletonHeight] = useState('100%');
    const [skeletonWidth, setSkeletonWidth] = useState('100%');

    useEffect(() => {
        const inputContainer = document.querySelector('.formTextArea', '.formInput'); // Замените '.inputContainer' на правильный селектор для вашего поля ввода
        if (inputContainer) {
            const inputContainerRect = inputContainer.getBoundingClientRect();
            setSkeletonHeight(`${inputContainerRect.height}px`);
            setSkeletonWidth(`${inputContainerRect.width}px`);
        }
    }, []);


    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['Вос', 'Пон', 'Втор', 'Сред', 'Четв', 'Пят', 'Суб'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сеп', 'Окт', 'Ноя', 'Дек'],
        today: 'Сегодня',
        clear: 'Очистить',
    });


    return (
        <>
            {isLoading ?
                <Skeleton height={skeletonHeight} width={skeletonWidth} count={1} borderRadius={6} />
                :
                <div className={style}>
                    {(subInput || textArea) &&
                        <div className='formHeader'>
                            {type !== 'boolean' &&
                                <label htmlFor={id} className={`subTitileFormInput ${required ? 'required' : ''}`}>
                                    {subInput}
                                    <span className='requiredStar'>{required && '*'}</span>
                                </label>
                            }
                            {(textArea && valueSet?.length > 0) &&
                                <div className={`copyContainer ${isCopy ? '' : 'active'}`} onClick={handleCopy}>
                                    <img src={icons.fileCopy}></img>
                                    <p>{isCopy ? 'Скопировано' : 'Скопировать'}</p>
                                </div>
                            }
                        </div>
                    }
                    <div className={`inputContainer ${buttonInput && 'buttonInputContainer'}`}>
                        <div className='flexInput'>
                            <div className='leftIconInpit'>{ico && <img src={ico} alt='Icon' />}</div>
                            {!textArea ? (

                                (options === undefined || options?.length === 0) ? (
                                    (() => {
                                        switch (type) {
                                            case 'phone':
                                                return (
                                                    <InputMask
                                                        name={keyData}
                                                        mask="+7 (999) 999-99-99"
                                                        maskChar="_"
                                                        placeholder="+7"
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={valueSet ? valueSet[0] === '8' ? valueSet.slice(1) : valueSet : ''}
                                                        onChange={(e) => handlePhoneChange(e)}
                                                        disabled={disabled || false}
                                                    />
                                                );
                                            case 'email':
                                                return (
                                                    <InputMask
                                                        name={keyData}
                                                        mask={mask || ''}
                                                        maskChar="_"
                                                        placeholder="example@example.com"
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={valueSet}
                                                        onChange={(e) => handleEmailMask(e)}
                                                        disabled={disabled || false}
                                                    />
                                                );
                                            case 'url':
                                                return (
                                                    <InputMask
                                                        name={keyData}
                                                        mask={mask || ''}
                                                        maskChar=""
                                                        placeholder="https://example.com"
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={value}
                                                        onChange={(e) => handleLink(e)}
                                                        disabled={disabled || false}
                                                    />
                                                );
                                            case 'boolean':
                                                return (
                                                    <ToggleButton
                                                        name={keyData}
                                                        text={subInput}
                                                        styleContainer={`containerToggle`}
                                                        classesText={'textToggle'}
                                                        formDataKey={keyData}
                                                        onToggle={handleToggle}
                                                        checked={checked}
                                                    />
                                                );
                                            case 'radio':
                                                return (
                                                    <input
                                                        id={id}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        value={valueSet}
                                                        onChange={handleToggle}
                                                        disabled={disabled || false}
                                                    />
                                                );
                                            case 'date':
                                                return (
                                                    <InputMask
                                                        name={keyData}
                                                        mask="99.99.9999"
                                                        maskChar="_"
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={date}
                                                        onChange={(e) => handleChange(e)}
                                                        disabled={disabled || false}
                                                    />
                                                    // выключено потому что не работал ввод по маске
                                                    // <Calendar
                                                    //     // mask='99.99.9999'
                                                    //     name={keyData}
                                                    //     value={date}
                                                    //     disabled={disabled}
                                                    //     onChange={(e) => handleChangeDate(e)}
                                                    //     dateFormat="dd.mm.yy"
                                                    //     inputClassName={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                    //     panelClassName={'classPanel'}
                                                    //     locale="ru" />
                                                );
                                            case 'datetime':
                                                return (
                                                    <InputMask
                                                        name={keyData}
                                                        mask="99.99.9999 99:99"
                                                        maskChar="_"
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={date}
                                                        onChange={(e) => handleChange(e)}
                                                        disabled={disabled || false}
                                                    />
                                                    // выключено потому что не работал ввод по маске
                                                    // <Calendar
                                                    //     // mask='99.99.9999'
                                                    //     name={keyData}
                                                    //     value={date}
                                                    //     disabled={disabled}
                                                    //     onChange={(e) => handleChangeDate(e)}
                                                    //     dateFormat="dd.mm.yy"
                                                    //     showTime
                                                    //     hourFormat="24"
                                                    //     inputClassName={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                    //     panelClassName={'classPanel'}
                                                    //     locale="ru" />
                                                );
                                            default:
                                                return mask ?
                                                    <InputMask
                                                        id={id}
                                                        name={keyData}
                                                        mask={mask || ''}
                                                        type={type}
                                                        maskChar=""
                                                        placeholder={placeholder || ''}
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={valueSet}
                                                        onChange={handleChange}
                                                        disabled={disabled || false}
                                                    /> :
                                                    <input
                                                        name={keyData}
                                                        id={id}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        className={`formInput ${isErr ? 'error' : ''} ${ico ? 'paddingIco' : ''}`}
                                                        value={valueSet}
                                                        onChange={handleChange}
                                                        disabled={disabled || false}
                                                    />
                                                    ;
                                        }
                                    })()
                                ) : (
                                    <FormSelector
                                        name={keyData}
                                        loadingScroll={loadingScroll}
                                        value={value}
                                        options={options}
                                        disabled={disabled}
                                        onChange={(e) => onChange(e)}
                                        error={isErr}
                                        ico={ico}
                                        onScroll={onScroll || null}
                                        onSearch={(e) => onSearch && onSearch(e)}
                                        multiple={multiple}
                                        placeholder={placeholder}
                                    />
                                )
                            ) : (
                                <>
                                    <textarea
                                        name={keyData}
                                        onBlur={handleChange}
                                        className='formTextArea'
                                        value={valueSet || ''}
                                        onChange={handleChange}
                                        onClick={() => setIsActive(false)}
                                    />
                                    <div className='characterCount'>
                                        <div className='emoji__container'>
                                            <div className='emoji'>
                                                <img src={icons.Smileys} onClick={() => setIsActive(!isActive)} alt='Emoji' />
                                                {isActive && <EmojiPicker onEmojiClick={handleEmojiSelect} searchDisabled />}
                                            </div>
                                        </div>
                                        <div className='question__container'>
                                            {valueSet?.length}
                                        </div>
                                    </div>
                                </>
                            )}

                            {question && (
                                <div className='rightIconInpit'>
                                    <img src={isErr ? icons.AlertCircle : icons.HelpCircle} alt='Icon' />
                                </div>
                            )}
                        </div>
                        {buttonInput && <Buttons ico={icoButton} text={textButton} onClick={() => { onChangeButton() }} className={styleButton} />}
                        {isErr && <p className='error'>{description}</p>}
                        {tip && <p>{tip}</p>}
                    </div>
                </div>
            }
        </>
    );
};

export default FormInput;
