import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles.scss';
import icons from '../../../assets/icons/icons';
import LandPlotApiRequest from '../../../api/Landplot/LandPlot';
import CommmentApiRequest from '../../../api/OldUploadMedia/OldUploadMedia';

const FilePicker = ({ dataFile, setFiles, type, isUpload, oldUpload }) => {
    const landPlot = new LandPlotApiRequest();
    const incomingApi = new CommmentApiRequest();
    const onDrop = useCallback((acceptedFiles) => {
        // Обработка выбранных файлов
        
        if (acceptedFiles.length > 0) {
            const formData = new FormData();

            acceptedFiles.forEach((file) => {
                formData.append('file', file);
            });

            !isUpload ?
                !oldUpload?
                    landPlot.uploadImage(formData).then((resp) => {
                        if (resp.success) {
                            if (dataFile) {
                                const mergedFiles = dataFile.concat(resp.data);
                                setFiles(mergedFiles);
                            } else {
                                setFiles(resp.data);
                            }
                        }
                    })
                    :
                    incomingApi.oldUploadImage(formData).then((resp) => {
                        if (resp.success) {
                            setFiles(resp.data);
                        }
                    })
                :
                setFiles(acceptedFiles);
        }

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: (() => {
            switch (type) {
                case 'image':
                    return { 'image/png': ['.jpg', '.png'] };
                case 'document':
                    return { 'text/html': ['.txt', '.docs', '.pdf', '.xlsx'] };
                case 'all':
                    return { 'text/html': ['.txt', '.docs', '.pdf', '.xlsx', '.jpg', '.png'] };
                case 'shape':
                    return { 'text/html': ['.shp', '.dbf', '.kml', '.zip', '.json'] };
                default:
                    // Можете установить здесь значение по умолчанию, если нужно
                    return null;
            }
        })(),
    });


    return (
        <div className={`file-picker-modal ${isDragActive ? 'active' : ''}`}>
            <div {...getRootProps()} className={`file-picker-dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />

                <div className='textUploadContainer'>
                    <img src={icons.fileDownload}></img>
                    <p className='textUpload'>
                        Перетащите файлы в эту область
                        <b>или нажмите, чтобы загрузить</b>
                        <span>
                            {
                                type === 'shape' ?
                                    "(Файлы одного из форматов: .kml, .json)"
                                    :
                                    " (до 10 файлов, размером не более 5 МБ)"
                            }
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FilePicker