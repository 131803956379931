import React, { Fragment, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataMicrodist, setDataPressItem } from '../../store/microdistDataReduces';
import { useHistory, useParams } from 'react-router-dom';
import { fieldToArray } from '../UI/functions/functions';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import GeoObjectReportApiRequest from '../../api/geoobjectReport/geoobjectReport';
import ImageThumbnail from '../UI/imageThumbnail/imageThumbnail';
import FilePicker from '../UI/FilePicker/FilePicker';
import ModalMap from '../ModalMap/ModalMap';
import mapColors from '../UI/mapColors';
import Button from '../Buttons/Button';
import { ReactComponent as Pen } from '../../assets/icons/Pencil.svg'

const GeoObjectContain = ({ data, isRedact }) => {
    const geoobjectReport = new GeoObjectReportApiRequest()
    const dispatch = useDispatch()
    const [dataOption, setOptions] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [isErr, setIsErr] = useState(false)
    const reportData = useSelector((state) => state.mocrodist_data)
    const history = useHistory()
    const { id } = useParams()
    const object_type = useMemo(() => {
        if (dataOption.length > 0) {
            if (reportData.object_type) {
                const options = dataOption?.find(item => item.key === 'object_type')
                const type = options?.value?.choices?.find(choice => choice.value === reportData.object_type)
                return type?.display_name
            }
        }
        return ''
    }, [reportData, dataOption])

    useEffect(() => {
        geoobjectReport.options().then((resp) => {
            const options = fieldToArray(resp.data.actions.create);
            setOptions(options);
        })
    }, [])

    useEffect(() => {
        console.log('reportData', reportData)
    }, [reportData])

    useEffect(() => {
        const dataField = fieldToArray(data);
        dataField?.forEach((item) => {
            dispatch(setDataPressItem([item.key, item.value]));
        })
    }, [data, dispatch])

    const handleChangeInput = (field, value) => {
        if (field === 'object_type') {
            console.log('object_type', reportData.object_type)
            console.log('coordinates', reportData.coordinates)
            dispatch(setDataPressItem(['coordinates', []]))
        }
        dispatch(setDataPressItem([field, value]))
    };

    const saveObject = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!reportData[item.key] || reportData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            geoobjectReport.create({ body: reportData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/geoobjects');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }
    const updateObject = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!reportData[item.key] || reportData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            geoobjectReport.update({ id: `${id}/`, body: reportData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/geoobjects');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }

    useEffect(() => {
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id]);

    const dataFields = {
        user_created: ['user'],
    };

    const renderedKeys = [
        ...dataFields.user_created,
    ];

    const classContin = {
        'date_start': 'col-1',
        'date_end': 'col-2',
    };

    return (
        <Fragment>
            {openModal &&
                <ModalMap
                    onClose={() => setOpenModal(false)}
                    titleHeader={'Добавить координаты объекта'}
                    subTitleHeader={''}
                    activeLandploat={false}
                    landplot={false}
                    isRedact={isRedact}
                    regionId={reportData?.id ? reportData.id : 4}
                    drawType={object_type === 'Канализация' || object_type === 'Газопровод' || object_type === 'Водопровод' ? 'polyline' : 'placemark'}
                    drawColor={object_type === 'Канализация' ? mapColors.yellow : object_type === 'Газопровод' ? mapColors.orange : object_type === 'Водопровод' ? mapColors.blue : mapColors.gray}
                    isCreateObject={true}
                />
            }
            <div className="continerPage">
                {
                    isErr &&
                    <ErrorMessage
                        message={isErr}
                        onClose={() => setIsErr(false)}
                        type={'error'}
                        onClick={() => {
                            saveObject()
                        }}
                    />
                }

                <div className="inputContainerCol">
                    {dataOption.map((item, index) => {
                        const key = item.key;
                        const value = item.value;

                        if (key === 'id' || key === 'coordinates' || renderedKeys.includes(key)) { // для координат выводинся кнопка
                            return null;
                        }
                        renderedKeys.push(key);

                        return (
                            <div className={classContin[key] || 'col-3'} key={index}>
                                <div className={classContin[key] || 'col-3'}>
                                    <div>
                                        {key === 'images' ?
                                            <>
                                                <h1>{item.value.label}</h1>
                                                <ImageThumbnail
                                                    imageData={!id ? reportData[item.key] : reportData[item.key]}
                                                    upload={id ? reportData[item.key] : false}
                                                    modal={true}
                                                />
                                                <FilePicker
                                                    type={'image'}
                                                    dataFile={''}
                                                    setFiles={(e) => { handleChangeInput(key, e) }}
                                                />
                                            </>
                                            :
                                            <>
                                                <FormInput
                                                    subInput={key === 'fields' ? `Поле ${index}` : value.label}
                                                    type={value.type}
                                                    options={value.choices}
                                                    keyData={key}
                                                    onChange={(e) => handleChangeInput(key, e)}
                                                    required={value.required}
                                                    key={index}
                                                    value={reportData[key]}
                                                    error={isErr}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='col-3'>
                        <Button
                            title={reportData?.coordinates?.length > 0 ? 'Изменить координаты' : 'Добавить координаты'}
                            Icon={Pen}
                            className={` _block _sm mt-0 ${reportData?.coordinates?.length > 0 ? 'btn-secondary' : 'btn _red'}`}
                            disabled={!(reportData.object_type && reportData.micro_district)}
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        />
                    </div>
                </div>

                {
                    isRedact &&
                    <Buttons
                        text={'Сохранить'}
                        className={'saveButton'}
                        ico={icons.floppyCheckmark}
                        onClick={() => {
                            id ? updateObject() : saveObject()
                        }}
                    />
                }
            </div>
        </Fragment>
    );
};

export default GeoObjectContain;
