import React, { Fragment, useEffect, useRef, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import { useSelector } from 'react-redux';
import icons from '../../assets/icons/icons';
import { getDateTime } from '../UI/functions/functions';
import apiConfig from '../../api/apiConfig';
import Buttons from '../Buttons/Buttons';
import CommentsApiRequest from '../../api/Comments/Comments';
import Modal from '../Modal/Modal';
import FilePicker from '../UI/FilePicker/FilePicker';
import ContentLoader from '../ContentLoader/ContentLoader';
import FileAttached from '../UI/FileAttached/FileAttached';

const LandploatFormComments = ({ stagesData, stagesOption, type }) => {
  const [commentsData, setCommentsData] = useState(stagesData?.comments);
  const [newMessage, setNewMessage] = useState('');
  const [newFile, setNewFile] = useState({ files: [] });
  const [prefixAdded, setPrefixAdded] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isAnsver, setIsAnsver] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const userData = useSelector((state) => state.user_data.userData);
  const microdistData = useSelector((state) => state.mocrodist_data);
  const commentsApi = new CommentsApiRequest();
  const socket = new WebSocket(
    `${apiConfig.baseUrlWSS}ws/notification/?token=${userData.token}`
  );
  const containerRef = useRef(null);

  useEffect(() => {
    setCommentsData(stagesData?.comments);
  }, []);

  useEffect(() => {
    socket.addEventListener('open', () => console.log('WebSocket открыт'));
    socket.addEventListener('close', () => console.log('WebSocket закрыт'));
    socket.addEventListener('error', (event) =>
      console.error('WebSocket ошибка', event)
    );
    socket.addEventListener('message', handleSocketMessage);

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [commentsData]);

  const handleSocketMessage = (event) => {
    const newComment = JSON.parse(event.data);
    if (newComment.model_class === "landplot") {
      const addCommentToParent = (comments, parentId) => {
        return comments.map((item) => {
          if (item.id === parentId) {
            return {
              ...item,
              answers: [...(item.answers || []), newComment],
            };
          } else if (item.answers && item.answers.length > 0) {
            return {
              ...item,
              answers: addCommentToParent(item.answers, parentId),
            };
          }
          return item;
        });
      };

      if (newComment?.text !== undefined || newComment?.file !== undefined) {
        if (newComment.parent) {
          setCommentsData((prevComments) => {
            return addCommentToParent(prevComments, newComment.parent);
          });
        } else {
          setCommentsData((prevComments) => [...prevComments, newComment]);
        }
      }
    }
  };

  const handleAddToFile = () => {
    setIsModal(true);
    setNewFile({ files: [] });
  };

  const handleAnsverMessage = (idAnsver) => {
    setIsAnsver(idAnsver);
    setPrefixAdded(false);
  };

  const handleNewMessage = () => {
    const newComment = {
      parent: isAnsver ? isAnsver : null,
      text: newMessage ? newMessage : '',
      author: userData.user_id,
      files: newFile.files,
      type: 'landplot',
      object_id: microdistData.inform_tab.id,
    };

    if (newMessage || newFile.files.length !== 0) {
      commentsApi.create({ body: newComment }).then((resp) => {
        if (resp.success) {
          setNewMessage('');
          setIsModal(false);
          setIsAnsver(undefined);
          setNewFile({ files: [] });
        }
      });
    } else {
      setErr('Вы ничего не написали, идите нахер спамеры ебаные');
    }
  };

  const renderFileLinks = (files) => {
    return (
      files?.length !== 0 &&
      files?.map((file) => (
        <a
          href={`${apiConfig.baseUrlMedia.slice(0, -1) + file?.file}`}
          download={`${apiConfig.baseUrlMedia.slice(0, -1) + file?.file}`}
          target="_blank"
          className="fileComment"
        >
          <img src={icons.file} />
          <p>{file?.file ? decodeURI(file.file) : ''}</p>
        </a>
      ))
    );
  };

  const renderComment = (item, level = 0) => {
    return (
      <div className={`${item.parent ? 'commentItemAnsver' : 'commentItem'}`} style={{ marginLeft: `${level === 0 ? level : level + 30}rem` }}>
        <div className='containerComment'>
          <img
            src={
              item?.author?.file.startsWith('https://')
                ? item?.author?.file
                // : apiConfig.baseUrlMedia + item?.author?.file
                : apiConfig.baseUrlMedia + (item?.author?.file.startsWith('/') ? item.author.file.replace(/^\//, '') : '')
            }
            className="avatarUser"
            alt="User Avatar"
          />
          <div className="commentContent">
            <h1 className="nameUser">{`${item?.author?.last_name} ${item?.author?.first_name} ${item?.author?.patronymic}`}</h1>
            <p className="textComment">{item.text}</p>
            {renderFileLinks(item?.files)}
            <div className={`footerComment ${item.id === isAnsver && 'active'}`}>
              <p className="timeComment">{getDateTime(item.upload_at)}</p>
              {!(item.id === isAnsver) && <span onClick={() => handleAnsverMessage(item.id)}>Ответить</span>}
              {item.id === isAnsver && renderMessageInput()}
            </div>
          </div>
        </div>
        {item.answers?.map((itemAnswer) => renderComment(itemAnswer, level + 1))}
      </div>

    );
  };

  const renderMessageInput = () => (
    <div className="inputMessage">
      <div>
        <FormInput
          placeholder={'Написать комментарий...'}
          onChange={(e) => {
            if (!prefixAdded) {
              setNewMessage(`${e}`);
              setPrefixAdded(true);
            } else {
              setNewMessage(e);
            }
          }}
          value={newMessage}
          error={err}
        />
        {newFile?.files.length !== 0 && renderFileLinks(newFile.files)}
      </div>
      <Buttons ico={icons.fileCopy} onClick={handleAddToFile} />
      <Buttons ico={icons.ArrowBackCol} onClick={handleNewMessage} />
    </div>
  );

  return (
    <Fragment>
      {isModal && (
        <Modal
          content={
            isLoading ? (
              <ContentLoader />
            ) : (
              <div className="containerModal">
                <h1>Прикрепить файлы</h1>
                <FilePicker
                  isUpload={false}
                  oldUpload={true}
                  type={'all'}
                  setFiles={(e) => {
                    setNewFile((prevFile) => ({
                      ...prevFile,
                      files: [...prevFile.files, ...e.map((item) => ({ file: decodeURI(item) }))]
                    }));
                  }}
                />
                {newFile?.files && <FileAttached oldUpload={true} filesData={newFile?.files} addFile={false} />}
                <div className="modalButtonContainer">

                  {/* <Buttons text={'Создать'} onClick={() => setIsModal(false)} /> */}
                  <Buttons text={'Отменить'} className={'greyButton'} onClick={() => setIsModal(false)} />
                </div>
              </div>
            )
          }
          onClose={() => !isLoading && setIsModal(false)}
        />
      )}
      <div className="continerCommentPage">

        <div className="col-1 blockComments">
          {/* <h1>{`Количество комментариев ${commentsData.length}`}</h1> */}
          {renderMessageInput()}
          {commentsData?.length !== 0 ? commentsData.map((item) => renderComment(item)).reverse() : <p>нет комментариев</p>}
        </div>
      </div>
    </Fragment>
  );
};

export default LandploatFormComments;
