import mapColors from "../../mapColors"

const getLandplotStrokeColor = (string) => {
    switch (string) {
        // Карта контроля строительства
        case 'STAGE_1':
            return mapColors.green
        case "STAGE_2":
            return mapColors.yellow
        case "STAGE_3":
            return mapColors.orange
        case "STAGE_4":
            return mapColors.cyan
        case "STAGE_5":
            return mapColors.cyan
        case "STAGE_6":
            return mapColors.grayblue

        // Остальные карты
        case 'В работе':
            return mapColors.lightblue
        case 'Первичная бронь':
            return mapColors.yellow
        case 'В продаже':
            return mapColors.green
        case 'Подтвержденная бронь':
            return mapColors.orange
        case "Реализовано":
            return mapColors.grayblue
        default:
            return mapColors.grayblue
    }
}

export default getLandplotStrokeColor