import { createSlice } from '@reduxjs/toolkit';
let filterState
try {
    filterState = localStorage.getItem('filterObject') ? JSON.parse(localStorage.getItem('filterObject')) : {};
} catch (e) {
    filterState = {}
}

export const dataObjectFilter = createSlice({
    name: 'data_objectFilter',
    initialState: filterState,
    reducers: {
        setDataObjectFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataObjectFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataObjectFilterPressItem, cleardataObjectFilter } = dataObjectFilter.actions;

export default dataObjectFilter.reducer;
